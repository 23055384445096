import React, { useState } from "react";
// Counter Up
import CountUp from "react-countup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VisibilitySensor from "react-visibility-sensor";
import { ImPlus } from "react-icons/im";
import { IconContext } from "react-icons";

const Counter = (props) => {
  const [counting, setCounting] = useState(false);
  const onChange = (isVisible) => {
    if (isVisible) {
      setCounting(true);
    }
  };

  return (
    <>
      <VisibilitySensor partialVisibility onChange={onChange}>
        <div className="about-counter-container">
          <div className="about-counter-icon-wrapper">
            <FontAwesomeIcon
              icon={props.icon}
              size={props.size}
              color="#ec0101"
            />
          </div>
          <div className="about-counter-details-wrapper">
            <div style={{ display: "flex" }}>
              <h2
                className="count"
                style={{
                  width: props.textWidth,
                  fontSize: "29px",
                }}
              >
                {counting ? (
                  <CountUp
                    start={props.countStart}
                    end={props.countEnd}
                    duration={3}
                  />
                ) : null}
              </h2>
              {props.plus && (
                <div className="about-counter-plus-container">
                  <IconContext.Provider value={{ color: "#032c56" }}>
                    <ImPlus />
                  </IconContext.Provider>
                </div>
              )}
            </div>
            <h5>{props.heading}</h5>
          </div>
        </div>
      </VisibilitySensor>
    </>
  );
};

export default Counter;
