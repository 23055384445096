import React from "react";
// Import OurAdventagesData
// Import OurAdvantageCard
import OurAdvantageCard from "../Our_Advantages/Our_Advantage_Card";
import img1 from "../../../assets/img/svg/house.svg";
import img2 from "../../../assets/img/svg/crm.svg";
import img3 from "../../../assets/img/svg/clock.svg";

const OurAdvantage = () => {
  return (
    <>
      <section id="our_advantages_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12 col-12">
              <div className="our_advantages_inner">
                <div className="heading-left-border">
                  <h2 style={{ marginBottom: "20px" }}>Our Advantages</h2>
                </div>
                <OurAdvantageCard
                  key={1}
                  img={img1}
                  heading="Safe and Secure"
                  para="Fully insured and tracked DVS compliant trucks."
                />
                <OurAdvantageCard
                  key={2}
                  img={img2}
                  heading="Fast Delivery"
                  para="We offer same day and next day delivery"
                />
                <OurAdvantageCard
                  key={3}
                  img={img3}
                  heading="Time Sensitive"
                  para="We can store cargo and schedule on time future deliveries."
                />

                {/* {OurAdventagesData.map((advantages, index) => (
                  <div className="our_advantages_item" key={index}>
                    {advantages.map((data, index) => (
                      <OurAdvantageCard
                        key={index}
                        img={data.img}
                        heading={data.heading}
                        para={data.para}
                      />
                    ))}
                  </div>
                ))} */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurAdvantage;
