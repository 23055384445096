import React from "react";
import SectionHeading from "../Common/SectionHeading";
import Descriptions from "./Descriptions";
import Table from "./Table";

const Services = () => {
  return (
    <>
      <section id="services_page">
        <div className="container">
          <SectionHeading
            heading="We Deliver For You"
            para="We provide outstanding transport solutions going above and
            beyond to create a great delivery service."
          />
        </div>
        <div className="container services-page-content-wrapper">
          <Descriptions />
          <Table />
        </div>
      </section>
    </>
  );
};

export default Services;
