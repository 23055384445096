import React from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const AboutUs = () => {
  const style = { fontSize: "18px", padding: "96px 0 64px", lineHeight: "1.6" };
  const linkStyle = {
    paddingBottom: "64px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "blue",
  };
  return (
    <>
      <section id="about_us">
        <div className="container">
          <p style={style}>
            M & G Haulage Ltd is a family owned business based in Rotherham
            South Yorkshire. We pride ourselves on providing a good reliable
            service and are approved hauliers for several blue chip companies.
            We always go out of our way to offer a great service.
          </p>
          <div style={linkStyle}>
            <Link
              to="/service"
              style={{
                color: "#ec0101",
                cursor: "pointer",
                fontWeight: "600",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ marginRight: "8px" }}>
                Read more about the services we offer
              </span>
              <FaArrowRight />
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
