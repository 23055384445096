import React from "react";
import HomeBanner from "../component/Home_One/Banner";
import LogisticsService from "../component/Home_One/Logistics_Services";
import HomeAbout from "../component/Home_One/About";
import OurAdvantage from "../component/Home_One/Our_Advantages";
import MapArea from "../component/Home_One/Map";
import Testimonials from "../component/Home_One/Testimonial";

const index = () => {
  return (
    <>
      <HomeBanner />
      <LogisticsService />
      <HomeAbout />
      <OurAdvantage />
      <MapArea />
      <Testimonials />
    </>
  );
};

export default index;
