import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/animate.min.css";
import "./assets/css/fontawesome.all.min.css";
import "./assets/css/color.css";
import "react-toastify/dist/ReactToastify.css";

ReactDOM.render(
  <React.Fragment>
    <App />
  </React.Fragment>,
  document.getElementById("root")
);
