export const MenuData = [
  {
    name: "Home",
    href: "/",
    has_children: false,
    // children: [
    //     {
    //         name: "Home Demo - 1",
    //         href: "/",
    //         has_children: false,
    //     },
    //     {
    //         name: "Home Demo - 2",
    //         href: "/home_two",
    //         has_children: false,
    //     },
    // ]
  },
  {
    name: "About Us",
    href: "/about",
    has_children: false,
  },
  {
    name: "Services",
    href: "/service",
    has_children: false,
  },
  {
    name: "Careers",
    href: "/careers",
    has_children: false,
  },
  {
    name: "Contact Us",
    href: "/contact",
    has_children: false,
  },
];
