import React from "react";
import CommonBanner from "../component/Common/Banner";
import AboutUs from "../component/AboutUs";
import MapArea from "../component/Home_One/Map";

const About = () => {
  return (
    <>
      <CommonBanner heading="About" page="About" />
      <AboutUs />
      <MapArea />
    </>
  );
};

export default About;
