import React from "react";

const Table = () => {
  return (
    <div className="service-page-service-wrapper">
      <h4 className=" service-page-descriptions-heading">
        <span className="bold">Our Fleet</span>
      </h4>
      <div className="service-page-table-container">
        <h5 className="service-page-table-header">Vehicle</h5>
        <h5 className="service-page-table-header">Weight Capacity</h5>
        <h5 className="service-page-table-header">Standard Pallets</h5>
        <span
          className="service-page-table-data"
          // style={{ backgroundColor: "#eaeaea" }}
        >
          LWB Van
        </span>
        <span
          className="service-page-table-data"
          // style={{ backgroundColor: "#eaeaea" }}
        >
          1200 kg
        </span>
        <span
          className="service-page-table-data"
          // style={{ backgroundColor: "#eaeaea" }}
        >
          4
        </span>
        <div
          className="service-page-table-data"
          style={{ backgroundColor: "#eaeaea" }}
        >
          7.5T
        </div>
        <div
          className="service-page-table-data"
          style={{ backgroundColor: "#eaeaea" }}
        >
          3600 kg
        </div>
        <span
          className="service-page-table-data"
          style={{ backgroundColor: "#eaeaea" }}
        >
          12
        </span>
        <span
          className="service-page-table-data"
          // style={{ backgroundColor: "#eaeaea" }}
        >
          18T Tail Lift
        </span>
        <span
          className="service-page-table-data"
          // style={{ backgroundColor: "#eaeaea" }}
        >
          9300 kg
        </span>
        <span
          className="service-page-table-data"
          // style={{ backgroundColor: "#eaeaea" }}
        >
          14-16
        </span>
        <span
          className="service-page-table-data"
          style={{ backgroundColor: "#eaeaea" }}
        >
          26T
        </span>
        <span
          className="service-page-table-data"
          style={{ backgroundColor: "#eaeaea" }}
        >
          16000 kg
        </span>
        <span
          className="service-page-table-data"
          style={{ backgroundColor: "#eaeaea" }}
        >
          16
        </span>
        <span
          className="service-page-table-data"
          // style={{ backgroundColor: "#eaeaea" }}
        >
          44T Various Trailers
        </span>
        <span
          className="service-page-table-data"
          // style={{ backgroundColor: "#eaeaea" }}
        >
          Large
        </span>
        <span
          className="service-page-table-data"
          // style={{ backgroundColor: "#eaeaea" }}
        ></span>
        <span
          className="service-page-table-data"
          style={{ backgroundColor: "#eaeaea" }}
        >
          Stanard Curtainsider
        </span>
        <span
          className="service-page-table-data"
          style={{ backgroundColor: "#eaeaea" }}
        >
          28000 kg
        </span>
        <span
          className="service-page-table-data"
          style={{ backgroundColor: "#eaeaea" }}
        >
          28
        </span>
      </div>
      <p>
        Our fleet of modern vehicles and specialized equipment is specifically
        designed to handle all sizes of delivery. From small single item
        time-sensitive to heavy-duty trucks with expansive cargo capacity. Our
        fleet is meticulously maintained and regularly updated to ensure the
        highest standards of safety and reliability.
      </p>
    </div>
  );
};

export default Table;
