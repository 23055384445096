import React from "react";
import { Link } from "react-router-dom";

// TopHeader Area
const TopHeader = () => {
  const getContent = () => {
    return (
      <div className="ec-wrapper">
        <ul className="left-list">
          <li className="hide-small hide-medium">
            <i
              className="far fa-clock fa-icon"
              style={{ marginRight: "5px" }}
            ></i>
            Mon - Fri / 07:00 - 19:00
          </li>
          <li>
            <a href="mailto:enquiry@mghaulage.co.uk">
              <i className="far fa-envelope fa-icon"></i>
              enquiry@mghaulage.co.uk
            </a>
          </li>
          <li>
            <a href="tel:+823-456-879">
              <i className="fas fa-phone-volume fa-icon"></i>
              01909 561894
            </a>
          </li>
        </ul>
        {/* <ul className="right-info hide-small">
          <Link to="/">
            <li className="mr-20" style={{ marginRight: "15px" }}>
              <i className="fab fa-facebook-f header-social-icon"></i>
            </li>
          </Link>
          <Link to="/">
            <li className="mr-20" style={{ marginRight: "15px" }}>
              <i className="fab fa-linkedin-in header-social-icon"></i>
            </li>
          </Link>
          <Link to="/">
            <li className="mr-20" style={{ marginRight: "15px" }}>
              <i className="fab fa-twitter header-social-icon"></i>
            </li>
          </Link>
          <Link to="/">
            <li className="mr-20">
              <i className="fab fa-instagram header-social-icon"></i>
            </li>
          </Link>
        </ul> */}
      </div>
    );
  };
  return (
    <>
      <div className="top-header">
        <div className="small-top-header">{getContent()}</div>
        <div className="large-top-header">
          <div className="container">{getContent()}</div>
        </div>
      </div>
    </>
  );
};

export default TopHeader;
