import React from "react";
//ContactInfo Area
const ContactInfo = () => {
  return (
    <>
      <div className="contact_info_wrapper">
        <div className="contact_item">
          <h5>Our Address</h5>
          <p>The Fold Yard</p>
          <p>Turner Croft</p>
          <p>Sheffield</p>
          <p>S25 1AA</p>
        </div>
        <div className="contact_item">
          <h5>Call us on</h5>
          <p>Office: +44 1909 561894</p>
        </div>
        <div className="contact_item">
          <h5>Mail Us at</h5>
          <p>enquiry@mghaulage.co.uk</p>
        </div>
      </div>
    </>
  );
};

export default ContactInfo;
