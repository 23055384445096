import React from "react";

const Careers = () => {
  const style = { fontSize: "18px", padding: "96px 0", lineHeight: "1.6" };
  return (
    <>
      <section id="careers">
        <div className="container">
          <p style={style}>
            We are not currently recruiting for any new roles but please feel
            free to enquire about future possibilities by email at
            enquiry@mghaulage.co.uk
          </p>
        </div>
      </section>
    </>
  );
};

export default Careers;
