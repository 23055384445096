import React from "react";

const Descriptions = () => {
  return (
    <div className="service-page-descriptions-wrapper">
      <div className="service-page-service-wrapper">
        <h4 className=" service-page-descriptions-heading">
          <span className="bold">General Haulage</span>
        </h4>
        <p>
          From long wheel base vans to articulated lorries, our versatile fleet
          ensures efficient delivery of goods, tailored to your specific needs.
          We use a mixed fleet of vehicles ranging from LWB vans to articulated
          lorries. All out vehicles are fully tracked and DVS compliant.
        </p>
      </div>
      <div className="service-page-service-wrapper">
        <h4 className=" service-page-descriptions-heading">
          <span className="bold">Large Scale Solutions</span>
        </h4>
        <p>
          At M & G Haulage, we are proud to offer a comprehensive and reliable
          large-scale delivery service tailored to meet the unique needs of
          businesses requiring transportation of goods on a grand scale. With
          our extensive experience in this area, we have established ourselves
          as a trusted partner for companies across various industries.
        </p>
      </div>
      <div className="service-page-service-wrapper">
        <h4 className=" service-page-descriptions-heading">
          <span className="bold">Time Sensitive Delivery</span>
        </h4>
        <p>
          We understand the critical importance of time-sensitive deliveries in
          today's fast-paced business environment and ensure the swift and
          reliable transportation of your goods, with an unwavering focus on
          meeting your tight deadlines and maintaining the integrity of your
          time-critical shipments by completing same day / next day deliviers.
        </p>
      </div>
    </div>
  );
};

export default Descriptions;
