import React from "react";
import CommonBanner from "../component/Common/Banner";
import CareersSection from "../component/Careers";
import MapArea from "../component/Home_One/Map";

const Careers = () => {
  return (
    <>
      <CommonBanner heading="Careers" page="Careers" />
      <CareersSection />
      <MapArea />
    </>
  );
};

export default Careers;
