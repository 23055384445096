import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  BrowserRouter,
} from "react-router-dom";
// Import Page Layout
import Navbar from "./component/Common/Navbar";
import Footer from "./component/Common/Footer";
import CopyRight from "./component/Common/CopyRight";
// Import All Components
import Home_One from "./page/index";
import About from "./page/About";
import Service from "./page/Service";
import Careers from "./page/Careers";
import PrivacyPolicy from "./page/PrivacyPolicy";
import TermsCondition from "./page/TermsCondition";
import Error from "./page/Error";
import Contact from "./page/Contact";

// Import ScrollToTop Components
import ScrollToTop from "./component/ScrollToTop";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Router>
          <ScrollToTop>
            <Navbar />
            <Switch>
              <Route path="/" exact component={Home_One} />
              <Route path="/about" exact component={About} />
              <Route path="/service" exact component={Service} />
              <Route path="/careers" exact component={Careers} />
              <Route path="/privacyPolicy" exact component={PrivacyPolicy} />
              <Route path="/terms" exact component={TermsCondition} />
              <Route path="/contact" exact component={Contact} />
              <Route exact component={Error} />
            </Switch>
            <Footer />
            <CopyRight />
          </ScrollToTop>
        </Router>
      </BrowserRouter>
    </>
  );
};

export default App;
