import React from "react";
// Import Counter
import Counter from "./Counter";
// About Img
import img1 from "../../../assets/img/home1/global-leader-2.png";
import img2 from "../../../assets/img/home1/sign.png";
// Counter Up
import {
  faPeopleGroup,
  faIndustry,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";

const HomeAbout = () => {
  return (
    <>
      <section id="home_about_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="about_img">
                <img src={img1} alt="About_Img" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="about_content">
                <div
                  className="heading-left-border"
                  style={{ paddingTop: "16px" }}
                >
                  <h2>About Us</h2>
                </div>
                <h3>
                  All our efforts and investments are geared towards offering
                  better haulage solutions.
                </h3>
                <p>
                  M & G Haulage Ltd is a family owned business based in
                  Rotherham South Yorkshire. We pride ourselves on providing a
                  good reliable service. We are approved hauliers for several
                  blue chip companies and enjoy working with smaller local
                  companies.
                </p>
              </div>
            </div>
          </div>
          <div className="about_counter_area">
            <div className="about-counter-wrapper">
              <div className="about-counter-card-container">
                <Counter
                  icon={faPeopleGroup}
                  heading="HAPPY CUSTOMERS"
                  countStart={100}
                  countEnd={15000}
                  size="4x"
                  plus={true}
                  textWidth="95px"
                />
              </div>
              <div className="about-counter-card-container">
                <Counter
                  icon={faIndustry}
                  heading="DELIVERIES MADE"
                  countStart={100}
                  countEnd={55000}
                  size="4x"
                  plus={true}
                  textWidth="98px"
                />
              </div>
              <div className="about-counter-card-container">
                <Counter
                  icon={faTruck}
                  heading="MILES COVERED"
                  countStart={100}
                  countEnd={6000000}
                  size="4x"
                  // plus={true}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeAbout;
