import React from "react";
// Import LogisticsCard
import LogisticsCard from "./LogisticsCard";
// import Section Heading
import SectionHeading from "../../Common/SectionHeading";
//  OwlCarousel Slider Import
import img1 from "../../../assets/img/home1/temp-service.jpg";
import { Link } from "react-router-dom";

const LogisticsService = () => {
  return (
    <>
      <section id="logistics_area">
        <div className="container">
          <SectionHeading
            heading="Our Services"
            para="We handle every last detail of our customers individual logistics and forwarding needs."
          />
          <Link to="/service">
            <div className="service-cards-wrapper">
              <LogisticsCard
                img={img1}
                heading={"General Haulage"}
                para={
                  "From long wheel base vans to articulated lorries, our versatile fleet ensures efficient delivery of goods, tailored to your specific needs."
                }
                key={1}
              />
              <LogisticsCard
                img={img1}
                heading={"Large-Scale Solutions"}
                para={
                  "Trust our reliable and efficient services for hassle-free and cost-effective transportation, regardless of the size or volume of your cargo."
                }
                key={2}
              />
              <LogisticsCard
                img={img1}
                heading={"Time Sensitive Delivery"}
                para={
                  "We prioritise urgency and efficiency, ensuring prompt transportation of your time-critical deliveries. "
                }
                key={3}
              />
            </div>
          </Link>
        </div>
      </section>
    </>
  );
};

export default LogisticsService;
