import React from "react";
import ContactForm from "../../Common/NewContactForm";

const HomeBanner = () => {
  return (
    <div className="section-container">
      <div className="container">
        <div className="content-wrapper">
          <div className="banner-content-wrapper">
            <div className="banner-text">
              <h1>Your complete transport solution</h1>
              <p>
                We provide outstanding transport solutions going above and
                beyond to create a great delivery service.
              </p>
            </div>
          </div>
          <div className="contact-form-wrapper">
            <div className="contact-form-box">
              <h3 className="contact-form-heading">Enquire Now</h3>
              <p className="contact-form-caption">
                We will get back to you within 24 hours
              </p>
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
