import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

//ContactMap Area
const ContactMap = () => {
  const mapStyles = {
    height: "50vh",
    width: "100%",
    margin: "0 0 0 0",
  };
  const defaultCenter = {
    lat: 53.425152,
    lng: -1.166576,
  };
  return (
    <>
      <LoadScript googleMapsApiKey="AIzaSyD7t9pnjtIlGzM2uWe4zq2PAG6B7IHyKuY">
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={11}
          center={defaultCenter}
        >
          <Marker position={defaultCenter} />
        </GoogleMap>
      </LoadScript>
    </>
  );
};

export default ContactMap;
