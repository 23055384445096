import React, { useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import BounceLoader from "react-spinners/BounceLoader";
import { ToastContainer, toast } from "react-toastify";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [sending, setSending] = useState(false);
  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);

  const toastifySuccess = () => {
    toast("Thank you for your enquiry!", {
      //   className: "submit-feedback success",
      toastId: "notifyToast",
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      type: "success",
    });
  };

  const onSubmit = async (data) => {
    const { name, email, phone, message } = data;
    setSending(true);

    try {
      const templateParams = {
        name,
        email,
        phone,
        message,
      };
        await emailjs.send(
          process.env.REACT_APP_EMAIL_SERVICE_ID, // service ID
          process.env.REACT_APP_EMAIL_TEMPLATE_ID, // template ID
          templateParams,
          process.env.REACT_APP_EMAIL_PUBLIC_KEY // public key
        );
      reset();
      setSending(false);
      setSent(true);
      toastifySuccess();
    } catch (e) {
      console.log(e);
      setError(true);
      setSending(false);
    }
  };

  const getBottom = () => {
    if (sending) {
      return (
        <div className="contact-form-sending" type="submit">
          <BounceLoader color="#fff" size={24} />
        </div>
      );
    }
    return (
      <button className="btn btn-theme contact-form-button" type="submit">
        Submit
      </button>
    );
  };

  return (
    <>
      <ToastContainer />
      <div className="contact-form-container">
        <form id="contact-form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="contact-form-row">
            <input
              type="text"
              name="name"
              className="contact-form-input"
              placeholder="Full Name*"
              {...register("name", {
                required: { value: true, message: "Please enter your name" },
                maxLength: {
                  value: 30,
                  message: "Please use 30 characters or less",
                },
              })}
            ></input>
            {errors.name && (
              <span className="contact-form-error">{errors.name.message}</span>
            )}
          </div>
          <div className="contact-form-row">
            <input
              type="email"
              name="email"
              className="contact-form-input"
              placeholder="Email*"
              {...register("email", {
                required: true,
                pattern:
                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              })}
            ></input>
            {errors.email && (
              <span className="contact-form-error">
                Please enter a valid email address
              </span>
            )}
          </div>
          <div className="contact-form-row">
            <input
              type="tel"
              name="phone"
              className="contact-form-input"
              placeholder="Phone*"
              {...register("phone", {
                required: {
                  value: true,
                  message: "Please enter a phone number",
                },
              })}
            ></input>
            {errors.phone && (
              <span className="contact-form-error">{errors.phone.message}</span>
            )}
          </div>
          <div className="contact-form-row">
            <textarea
              rows={3}
              name="message"
              className="contact-form-input"
              placeholder="Message*"
              {...register("message", {
                required: { value: true, message: "Please enter a message" },
                maxLength: {
                  value: 1000,
                  message: "Subject cannot exceed 1000 characters",
                },
              })}
            ></textarea>
            {errors.message && (
              <span className="contact-form-error">
                {errors.message.message}
              </span>
            )}
          </div>
          {getBottom()}
        </form>
        {sent && (
          <div className="contact-form-sent-message">
            Thank you for contacting us. We will be in touch shortly.
          </div>
        )}
        {error && (
          <div className="contact-form-sent-message">
            Sorry, there was an error sending the enquiry. Please refresh and
            try again.
          </div>
        )}
      </div>
    </>
  );
};

export default ContactForm;
