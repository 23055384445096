import logo1 from "../../../assets/img/home1/partner-1.png";
import logo2 from "../../../assets/img/home1/partner-2.png";
import logo3 from "../../../assets/img/home1/partner-3.png";
import logo4 from "../../../assets/img/home1/partner-4.png";
import logo5 from "../../../assets/img/home1/partner-5.png";

export const TestimonialsData = [
  {
    para: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam`,

    name: "David Huxham",
    dest: "Founder, Huxham Inc",
  },
  {
    para: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam`,

    name: "David Huxham",
    dest: "Founder, Huxham Inc",
  },
  {
    para: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam`,

    name: "David Huxham",
    dest: "Founder, Huxham Inc",
  },
  {
    para: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam`,

    name: "David Huxham",
    dest: "Founder, Huxham Inc",
  },
  {
    para: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam`,

    name: "David Huxham",
    dest: "Founder, Huxham Inc",
  },
];

export const ClientLogo = [
  {
    img: logo1,
  },
  {
    img: logo2,
  },
  {
    img: logo3,
  },
  {
    img: logo4,
  },
  {
    img: logo5,
  },
];
