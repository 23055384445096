import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Import Logo
import logo from "../../../assets/img/logo/original/logo-transparent.png";
// Import TopHeader
import TopHeader from "../TopHeader";
// Import MenuData
import { MenuData } from "./MenuData";
// Import MenuItems
import MenuItems from "./MenuItems";
// Import SearchForm
import SubMenuItems from "./SubMenuItems";
// Import Icon
import { HiMenuAlt3 } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";

const Navbar = () => {
  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.querySelector(".navbar-area");
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };
  //Responsive Menu Area
  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick(!click);
  };

  const handleLinkClick = () => {
    setClick(false);
  };

  return (
    <>
      <header className="header-area">
        <TopHeader />
        <div className="navbar-area">
          <div className="transTics-nav">
            <div className="large-navbar">
              <div className="container">
                <nav className="navbar-wrapper">
                  <Link className="navbar-logo" to="/">
                    <img src={logo} alt="logo" />
                  </Link>
                  <ul className="menu-items-wrapper">
                    {MenuData.map((item, index) => (
                      <MenuItems item={item} key={index} />
                    ))}
                  </ul>
                </nav>
              </div>
            </div>
            <div className="small-navbar">
              <nav className="navbar-wrapper">
                <Link className="navbar-logo" to="/">
                  <img src={logo} alt="logo" />
                </Link>
              </nav>
              <div className="responsive-button" onClick={handleClick}>
                {click ? <AiOutlineClose /> : <HiMenuAlt3 />}
              </div>
              <SlideDown className={"my-dropdown-slidedown"}>
                {click ? (
                  <div className="slidedown-container">
                    <ul className="slidedown-list">
                      {MenuData.map((item, index) => (
                        <SubMenuItems
                          item={item}
                          key={index}
                          handleClick={handleLinkClick}
                        />
                      ))}
                    </ul>
                  </div>
                ) : null}
              </SlideDown>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
