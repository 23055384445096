import React from "react";
// OurAdvantageCard Area
const OurAdvantageCard = (props) => {
  return (
    <div className="advantage-card-container">
      <div className="advantage-card-image-wrapper">
        <img
          className="advantage-card-image"
          src={props.img}
          alt="Icons Advantage"
        />
      </div>
      <div className="advantage-card-captions-wrapper">
        <h4 className="advantage-card-heading">{props.heading}</h4>
        <span className="advantage-card-caption">{props.para}</span>
      </div>

      {/* <div className="advantages_items">
        <div className="advantages_icons">
          <img src={props.img} alt="Icons Advantage" />
        </div>
        <div className="advantages_text">
          <h4>{props.heading}</h4>
          <p>{props.para}</p>
        </div>
      </div> */}
    </div>
  );
};

export default OurAdvantageCard;
