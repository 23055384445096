import React from "react";
import CommonBanner from "../component/Common/Banner";
import Services from "../component/Service/ServicesCard";
import MapArea from "../component/Home_One/Map";

const Service = () => {
  return (
    <>
      <CommonBanner heading="Services" page="Services" />
      <Services />
      <MapArea />
    </>
  );
};

export default Service;
